import React, {useRef, useEffect} from 'react'
import PrimaryButton from '../components/primary-button'

function ShowcaseFeatureItem({featureImage, featureHeading, featureDescription, position}) {
  const featureDetailsRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate-fade-up');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (featureDetailsRef.current) {
      observer.observe(featureDetailsRef.current);
    }

    return () => {
      if (featureDetailsRef.current) {
        observer.unobserve(featureDetailsRef.current);
      }
    };
  }, []);
  return (
    <div className={`flex flex-row gap-20 ${position == "left" ? "flex-row-reverse justify-start" : "justify-end"} w-[1024px] m-auto p-5`}>
      <div className={`w-[700px] h-[500px] height-full bg-gradient-to-br from-orange-400 to-purple-600 rounded-lg`}>
        <img src={featureImage} className="rounded-md" />
      </div>
      <div className="flex flex-col justify-center align-center gap-5 h-[500px] w-[550px] opacity-0" ref={featureDetailsRef}>
        <div className="flex flex-col gap-4">
        <span className="font-semibold text-[2.5rem]">
          {featureHeading}
        </span>
        <div className="font-medium text-lg text-info">
          {featureDescription}
        </div>
        </div>
        <div className="flex">
        <PrimaryButton buttonText="Get Started" />
        </div>
      </div>
    </div>

  )
}

export default ShowcaseFeatureItem