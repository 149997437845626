import React from 'react'
import ProductFeatureItem from './product-feature-item'
import { GoIssueOpened, GoPeople } from 'react-icons/go'
import { BsKanban } from 'react-icons/bs'

function ProductFeaturesSection() {
  return (
    <div className="flex flex-row gap-5 justify-center align-center pt-10 pb-10 w-[950px] m-auto">
        <ProductFeatureItem 
          productFeatureIcon={<GoPeople className="text-3xl"/>}
          productFeatureName="Multiple Teams"
          productFeatureDescription="Your teams all in one space"
        />
        <ProductFeatureItem 
          productFeatureIcon={<BsKanban className="text-3xl"/>}
          productFeatureName="View Options"
          productFeatureDescription="View your issues/tasks just the way you want"
        />
        <ProductFeatureItem 
          productFeatureIcon={<GoIssueOpened className="text-3xl" />}
          productFeatureName="Backlog"
          productFeatureDescription="Track and manage your projects backlog"
        />
    </div>
  )
}

export default ProductFeaturesSection