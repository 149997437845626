import React, {useEffect, useRef} from 'react'
import PrimaryButton from './primary-button'
import UnstyledButton from './unstyled-button'

function Hero() {
    const heroImageRef = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('animate-fade-up');
              observer.unobserve(entry.target);
            }
          });
        },
        { threshold: 0.5 }
      );
  
      if (heroImageRef.current) {
        observer.observe(heroImageRef.current);
      }
  
      return () => {
        if (heroImageRef.current) {
          observer.unobserve(heroImageRef.current);
        }
      };
    }, []);
  return (
    <div>
        <div className="p-5 h-screen flex align-center justify-center pt-0">
        <div className="box-outline absolute">
            
        </div>
        <div className="z-10 text-center max-w-[500px] flex align-center justify-center flex-col gap-5 blur-fade-move-animation">
            <div className="flex align-center justify-center animate-fade-up opacity-0">
              <img src="/showcase-icon.svg" className="w-[95px] h-[95px]"/>
            </div>
            <span className="text-[4.5rem] font-medium gradient-text animate-fade-up opacity-0">Build your <br /> projects, better</span>
            <div>
                <span className="text-[1.2rem] font-regular text-info">
                    Meet the project management tool for your every project. Manage Projects, Issues and Progress.
                </span>
                <div className="flex flex-row align-center justify-center p-5 gap-4">
                    <PrimaryButton buttonText="Get Started" />
                    <UnstyledButton buttonText="Changelog" />
                </div>
            </div>
        </div>
    </div>
    <div className="flex align-center justify-center">
        <img src="/hero-showcase-image.svg" className="w-[950px] object-fit z-10 rounded-md opacity-0" ref={heroImageRef}/>
    </div>
    </div>
  )
}

export default Hero