import React, { useRef, useEffect } from 'react';
import FormButton from '../components/form-button';

function FullShowcaseItem({ position }) {
  const featureDetailsRef = useRef(null);
  const blurFadeMoveRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('animate-fade-up');
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    const blurFadeObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('blur-fade-move-animation');
            blurFadeObserver.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.8 }
    );

    if (featureDetailsRef.current) {
      observer.observe(featureDetailsRef.current);
    }

    if (blurFadeMoveRef.current) {
      blurFadeObserver.observe(blurFadeMoveRef.current);
    }

    return () => {
      if (featureDetailsRef.current) {
        observer.unobserve(featureDetailsRef.current);
      }
      if (blurFadeMoveRef.current) {
        blurFadeObserver.unobserve(blurFadeMoveRef.current);
      }
    };
  }, []);

  return (
    <div className="w-full bg-purple-linear-gradient pt-[1.5rem]">
      <div className="flex flex-col gap-5 pt-3 pb-3 w-[1024px] m-auto justify-center items-center">
        <div className="flex flex-col w-[1024px] justify-center items-center h-full gap-5 opacity-0 text-center opacity-0 animate-fade-up" ref={featureDetailsRef}>
          <div className="flex flex-col w-full gap-2 justify-start pb-8 pt-8">
            <span className="text-[4.5rem] font-medium animate-fade-up opacity-0">Your Project <br /> builds better on Project.A</span>
            <div className="flex flex-col gap-5 justify-end">
              <div className="font-medium text-lg text-info">
              We're gradually opening up early access to people and companies.
              </div>
            </div>
          </div>
        </div>
        <div className="w-full h-[535px] height-full bg-gradient-to-br from-orange-400 to-purple-600 rounded-lg overflow-hidden border-[1px] border-bg-border-lighter relative">
          <div className="absolute w-full h-full bg-bg opacity-[0.8] z-10"></div>
          <div className="absolute w-full h-full z-50 flex flex-col items-center justify-center opacity-0" ref={blurFadeMoveRef}>
            <div className="flex flex-col gap-2 w-[450px] pb-5 text-center"  id="join-waitlist">
              <span className="text-[2.5rem] font-medium gradient-text">Join our Waitlist</span>
              <div className="font-medium text-lg text-info">Join our waitlist and be the first to experience your favourite project management tool</div>
            </div>
            <form className="w-[350px] flex flex-col items-center justify-center gap-3"

              action="https://formspree.io/f/mrbzkpgq"
              method="POST"
            >
              <input className="bg-bg w-full border-[1px] border-bg-border-lighter rounded-md p-2 outline-none hover:shadow-md transition-all hover:bg-bg-lighter" placeholder="Full Name, eg. Muna Dasgupta" name="firstName" required="true" />
              <input className="bg-bg w-full border-[1px] border-bg-border-lighter rounded-md p-2 outline-none hover:shadow-md transition-all hover:bg-bg-lighter" placeholder="Email, eg. munadas@rocketos.com" name="email" required="true" />
              <FormButton buttonText="Submit" />
</form>
          </div>
          <img src="./features-images/showcase-image-01.png" alt="Showcase" />
        </div>
      </div>
    </div>
  );
}

export default FullShowcaseItem;
