import logo from './logo.svg';
import './App.css';
import Navbar from './components/navbar';
import Hero from './components/hero';
import ProductFeaturesSection from './components/product-features-section';

import ShowcaseFeatureItem from './components/showcase-feature-item'
import FullShowcaseItem from './components/full-showcase-item';
import Footer from './components/footer';

function App() {
  return (
    <div className="gyan-container">
      <Navbar />
      <Hero />
      <ProductFeaturesSection />
      <div className="flex flex-col gap-5">
      <ShowcaseFeatureItem featureImage="./features-images/feature-image-01.png" featureHeading={<div>More Work <br /> Less Managing</div>} featureDescription="With Project.A, we are creating an project mangement tool for your dev work, Project.A is the new standard to build." position="right" />
      <ShowcaseFeatureItem featureImage="./features-images/feature-image-02.png" featureHeading={<div>See Your Projects <br /> Progress Fast</div>} featureDescription="Projects let you group issues and get a birds-eye overview of the progress your team is making" position="left" />
      <ShowcaseFeatureItem featureImage="./features-images/feature-image-03.png" featureHeading={<div>See Your Project <br /> Activity In <span className="gradient-text">Inbox</span></div>} featureDescription="Your inbox how it was always meant to be." position="right" />
      <FullShowcaseItem />
      <Footer />
      </div>
    </div>
  );
}

export default App;
