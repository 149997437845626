import React from 'react'

function ProductFeatureItem({productFeatureIcon, productFeatureName, productFeatureDescription}) {
  return (
    <div className="flex flex-col gap-3 p-5 w-1/3 bg-card-linear-gradient shadow-md border-[1px] border-bg-border-lighter rounded-md">
        <div>
          {productFeatureIcon}
        </div>
        <div className="flex flex-col gap-4">
        <div className="text-white font-semibold text-xl">
            {productFeatureName}
        </div>
        <div className="text-info font-normal text-sm w-[200px]">
            {productFeatureDescription}
        </div>
        </div>
    </div>
  )
}

export default ProductFeatureItem