import React, { useRef, useEffect, useState } from 'react';

function FormButton({ buttonText }) {
  const buttonRef = useRef(null);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsInView(true);
            observer.unobserve(entry.target);
          } else {
            setIsInView(false);
          }
        });
      },
      { threshold: 0.1 }
    );

    if (buttonRef.current) {
      observer.observe(buttonRef.current);
    }

    return () => {
      if (buttonRef.current) {
        observer.unobserve(buttonRef.current);
      }
    };
  }, []);

  return (
    <button
      ref={buttonRef}
      className={`p-3 rounded-full bg-primary font-semibold text-m flex align-center justify-center pl-5 pr-5 cursor-pointer hover:shadow-md hover:bg-primary-lighter transition-all ${isInView ? 'light' : ''}`}
      type="submit"
    >
      {buttonText}
    </button>
  );
}

export default FormButton;
