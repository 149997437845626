import React from 'react'

function PrimaryButton({buttonText}) {
  return (
    <a className="p-3 rounded-full bg-primary font-semibold text-m flex align-center justify-center pl-5 pr-5 cursor-pointer hover:shadow-md hover:bg-primary-lighter transition-all" href="#join-waitlist">
        {buttonText}
    </a>
  )
}

export default PrimaryButton