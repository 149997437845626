import React from 'react'

function UnstyledButton({buttonText}) {
  return (
    <div className="p-3 rounded-full font-semibold text-m flex align-center justify-center pl-5 pr-5 cursor-pointer hover:shadow-md transition-all">
        {buttonText}
    </div>
  )
}

export default UnstyledButton